<template>
  <div
    class="companyInternet"
    v-watermark
  >
    <commonNavBar :title="'单位物联设备查询'"></commonNavBar>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="value"
          show-action
          :placeholder="placeholder"
          left-icon=""
          @search="onSearch"
          @input="onInput"
          autofocus
        >
          <template #action>
            <div @click="searchBtn">搜索</div>
          </template>
          <template #label>
            <van-dropdown-menu active-color="#1989fa">
              <van-dropdown-item
                v-model="type"
                :options="option1"
                @change="typeChange"
              />
            </van-dropdown-menu>
          </template>
        </van-search>
      </form>
      <!-- 搜索历史 -->
      <div
        class="searchBar"
        v-if="historyList.length && !value.length"
      >
        <span class="label">搜索历史</span>
        <svg-icon
          icon-class="delet"
          className="myIcon"
          @click="delClick"
        />
      </div>
      <div
        class="searchList"
        v-if="historyList.length && !value.length"
      >
        <van-cell-group>
          <van-cell
            :title="item"
            v-for="(item, index) in historyList"
            :key="index"
            @click="historyClick(item)"
          />
        </van-cell-group>
      </div>
      <div
        class="suggestionList"
        v-else-if="value.length"
      >
        <van-cell-group>
          <van-cell
            v-for="(item, index) in suggestionList"
            :key="index"
            center
            @click="suggestionListClick(item)"
          >
            <template #default>
              <van-tag
                type="success"
                v-if="item.status === 0"
              >在用</van-tag>
              <van-tag
                type="primary"
                v-else-if="item.status === 1"
              >拆回</van-tag>
              <van-tag
                type="warning"
                v-else-if="item.status === 2"
              >维修</van-tag>
              <van-tag
                type="danger"
                v-else-if="item.status === 3"
              >报废</van-tag>
              <van-tag
                color="#aaaaaa"
                v-else-if="item.status === 4"
              >遗失</van-tag>
            </template>
            <template #label>
              <div v-html="handlerSuggestionList(item.companies) || '--'"></div>
            </template>
            <template #title>
              <div v-html="handlerSuggestionListByCode(item.code)"></div>
            </template>
          </van-cell>
        </van-cell-group>
      </div>
      <div
        v-else
        class="emptyBox"
      >
        <van-empty
          image="search"
          description="没有任何历史搜索"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import {
  deleteSearchHistory,
  getSearchHistory,
  postSearchHistory
} from '@/api/system'
import { getCompanyInternetList } from '@/api/companyInternet'
let timeId = null

export default {
  name: 'contactSearch',
  data() {
    return {

      value: '',
      type: 1,
      option1: [
        { text: '单位名', value: 1 },
        { text: '设备ID', value: 0 },
        { text: '编号', value: 2 },
        { text: '卡号', value: 4 }
      ],
      historyList: [],
      suggestionList: []
    }
  },
  // 路由进入页面
  beforeRouteEnter(to, from, next) {
    if (from.path === '/') {
      next(async vm => {
        Object.assign(vm.$data, vm.$options.data.call(vm))
        const { data } = await getSearchHistory({
          searchType: 'companyInternetByName'
        })
        vm.historyList = [...data.data].slice(0, 20)
        vm.$nextTick(() => {
          if (document.querySelector('input')) {
            document.querySelector('input').focus()
          }
        })
      })
    } else {
      next(async vm => {
        vm.value = ''
        if (vm.type === 0) {
          const { data } = await getSearchHistory({
            searchType: 'companyInternetById'
          })
          vm.historyList = [...data.data].slice(0, 20)
        }
        if (vm.type === 1) {
          const { data } = await getSearchHistory({
            searchType: 'companyInternetByName'
          })
          vm.historyList = [...data.data].slice(0, 20)
        }
        if (vm.type === 2) {
          const { data } = await getSearchHistory({
            searchType: 'companyInternetByCode'
          })
          vm.historyList = [...data.data].slice(0, 20)
        }
        if (vm.type === 4) {
          const { data } = await getSearchHistory({
            searchType: 'companyInternetByCardCode'
          })
          vm.historyList = [...data.data].slice(0, 20)
        }
        vm.suggestionList = []
        vm.$nextTick(() => {
          if (document.querySelector('input')) {
            document.querySelector('input').focus()
          }
        })
      })
    }
  },
  // 路由离开页面
  beforeRouteLeave(to, from, next) {
    clearTimeout(timeId)
    next()
  },
  computed: {
    placeholder() {
      if (this.type === 0) {
        return '请输入设备ID'
      } else if (this.type === 1) {
        return '请输入单位名称'
      } else if (this.type === 4) {
        return '请输入设备卡号'
      } else {
        return '请输入单位编号'
      }
    },
    // 使用计算属性处理高亮
    handlerSuggestionList() {
      return val => {
        if (this.type === 1) {
          const reg = new RegExp(this.value, 'gi')
          const newItem = val.map((item) => item.name).join(',').replace(reg, match => {
            return `<span style='color:#1990ff'>${match}</span>`
          })
          return newItem
        } else {
          return val.map((item) => item.name).join(',')
        }
      }
    },
    handlerSuggestionListByCode() {
      return val => {
        if (this.type === 0) {
          const reg = new RegExp(this.value, 'gi')
          const newItem = val.replace(reg, match => {
            return `<span style='color:#1990ff'>${match}</span>`
          })
          return newItem
        } else {
          return val
        }
      }
    }
  },
  methods: {
    // 删除历史
    delClick() {
      Dialog.confirm({
        title: '删除搜索历史',
        message: '确认清除历史记录？'
      })
        .then(() => {
          this.historyList = []
          if (this.type === 0) {
            deleteSearchHistory({
              searchType: 'companyInternetById'
            })
          }
          if (this.type === 1) {
            deleteSearchHistory({
              searchType: 'companyInternetByName'
            })
          }
          if (this.type === 2) {
            deleteSearchHistory({
              searchType: 'companyInternetByCode'
            })
          }
          if (this.type === 4) {
            deleteSearchHistory({
              searchType: 'companyInternetByCardCode'
            })
          }
        })
        .catch(() => { })
    },
    // 输入搜索框内容
    onInput(val) {
      if (val) {
        this.suggestionList = []
        clearTimeout(timeId)
        timeId = setTimeout(async () => {
          const { data } = await getCompanyInternetList({
            type: this.type,
            'page.current': 1,
            'page.size': 10,
            'sort.key': '0',
            'sort.value': 'desc',
            search: val,
            fuzzy: this.type === 1 ? 1 : 2,
            firstMatch: this.type === 2 || this.type === 0 ? 0 : null
          })
          this.suggestionList = [...data.data.records]
        }, 300)
      } else {
        this.suggestionList = []
      }
    },
    // 搜索
    onSearch(val) {
      if (val) {
        this.$router.push({
          path: '/workTable/companyInternetSystemCode',
          query: {
            text: val,
            type: this.type
          }
        })
        if (this.type === 0) {
          postSearchHistory({
            searchType: 'companyInternetById',
            searchTexts: JSON.stringify([this.value])
          })
        }
        if (this.type === 1) {
          postSearchHistory({
            searchType: 'companyInternetByName',
            searchTexts: JSON.stringify([this.value])
          })
        }
        if (this.type === 2) {
          postSearchHistory({
            searchType: 'companyInternetByCode',
            searchTexts: JSON.stringify([this.value])
          })
        }
        if (this.type === 4) {
          postSearchHistory({
            searchType: 'companyInternetByCardCode',
            searchTexts: JSON.stringify([this.value])
          })
        }
      }
    },
    // 点击搜索按钮
    searchBtn() {
      this.onSearch(this.value)
    },
    // 点击搜索建议
    suggestionListClick(item) {
      this.$router.push(`/workTable/companyInternetAddressBookInfo/${item.id}`)
      if (this.type === 0) {
        postSearchHistory({
          searchType: 'companyInternetById',
          searchTexts: JSON.stringify([item.code])
        })
      }
      if (this.type === 1) {
        postSearchHistory({
          searchType: 'companyInternetByName',
          searchTexts: JSON.stringify([item.companyName])
        })
      }
      if (this.type === 2) {
        postSearchHistory({
          searchType: 'companyInternetByCode',
          searchTexts: JSON.stringify([item.companyCode])
        })
      }
      if (this.type === 4) {
        postSearchHistory({
          searchType: 'companyInternetByCardCode',
          searchTexts: JSON.stringify([item.cardNumber])
        })
      }
    },
    // 点击历史
    historyClick(val) {
      this.$router.push({
        path: '/workTable/companyInternetSystemCode',
        query: {
          text: val,
          type: this.type
        }
      })
      if (this.type === 0) {
        postSearchHistory({
          searchType: 'companyInternetById',
          searchTexts: JSON.stringify([val])
        })
      }
      if (this.type === 1) {
        postSearchHistory({
          searchType: 'companyInternetByName',
          searchTexts: JSON.stringify([val])
        })
      }
      if (this.type === 2) {
        postSearchHistory({
          searchType: 'companyInternetByCode',
          searchTexts: JSON.stringify([val])
        })
      }
      if (this.type === 4) {
        postSearchHistory({
          searchType: 'companyInternetByCardCode',
          searchTexts: JSON.stringify([val])
        })
      }
    },
    // 搜索类型点击
    async typeChange(val) {
      this.historyList = []
      this.value = ''
      this.suggestionList = []
      if (val === 0) {
        const { data } = await getSearchHistory({
          searchType: 'companyInternetById'
        })
        this.historyList = [...data.data].slice(0, 20)
      }
      if (val === 1) {
        const { data } = await getSearchHistory({
          searchType: 'companyInternetByName'
        })
        this.historyList = [...data.data].slice(0, 20)
      }
      if (val === 2) {
        const { data } = await getSearchHistory({
          searchType: 'companyInternetByCode'
        })
        this.historyList = [...data.data].slice(0, 20)
      }
      if (val === 4) {
        const { data } = await getSearchHistory({
          searchType: 'companyInternetByCardCode'
        })
        this.historyList = [...data.data].slice(0, 20)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.companyInternet {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #eeeeee;

  ::v-deep {
    .searchBox {
      height: calc(100% - 46px);
      overflow: auto;

      .van-search__content {
        padding-left: 0;
      }

      .van-dropdown-menu {
        height: 100%;
        width: 100%;
        background-color: transparent;
        overflow: hidden;

        .van-dropdown-menu__bar {
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          background-color: transparent;
          box-shadow: none;

          .van-ellipsis {
            padding-right: 5px;
            font-size: 14px;
          }
        }

        .van-dropdown-menu__title::after {
          right: 1px;
        }
      }

      .van-dropdown-item {
        .van-dropdown-item__content {
          .van-dropdown-item__option {
            .van-cell__title {
              padding-left: 25px;
              font-size: 14px;
            }
          }
        }
      }

      .searchBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;
        height: 24px;

        .label {
          font-size: 12px;
          color: #aaaaaa;
        }

        .myIcon {
          width: 20px;
          height: 20px;
        }
      }

      .emptyBox {
        height: calc(100% - 54px);
        position: relative;

        .van-empty {
          width: 100%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .searchList {
      max-height: calc(100% - 54px - 24px);
      overflow: auto;
      background-color: white;

      .van-cell-group {
        .van-cell {
          min-height: 44px;
        }
      }
    }

    .suggestionList {
      max-height: calc(100% - 54px);
      overflow: auto;
      background-color: white;
    }
  }
}
</style>
